.loading-screen-container {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background: var(--black);
	z-index: 99999999999999;
	opacity : 1;
}

.subsequent-loading-screen {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 99999999999999;
	font-size: 3.2rem;
	overflow: hidden;
	color: var(--white);
	background: var(--black);
	display: flex;
	justify-content: center;
	align-items: center;
	opacity : 0;
	transition: opacity 0.3s;
}

.loading-screen {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background: var(--black);
	z-index: 99999999999999;
	font-size: 3.2rem;
	color: var(--white);
	overflow: hidden;
	opacity : 0;
	transition: opacity 1s;
}

.loading-screen.showing, .subsequent-loading-screen.showing {
	opacity: 1;
}

.loading-text-container {
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	background: var(--black);
	opacity: 0.5;
	height: 3.2rem;
}

.loading-text {
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	text-align: center;
	text-shadow: 5px 3px 4px rgb(0 0 0 / 50%);
}

.loading-dots {
	position: absolute;
	text-shadow: 5px 3px 4px rgb(0 0 0 / 50%);
}