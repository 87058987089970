.profile {
	display: flex;
	overflow: auto;
	max-height: 100%;
}

.profile p {
	padding: 0;
	margin: 0;
}

.profile h3, .profile .character-description-title {
	font-size: 3.2rem;
	font-weight: 600;
	color: var(--secondary);
	margin: 0;
	margin-top: 3.2rem;
}

.profile-sidebar {
	padding: 0 2rem;
	flex-basis: 870px;
	overflow: hidden;
}

.status-container, .items-container {
	display: grid;
	grid-template-columns: repeat(2, auto);
	grid-column-gap: 1.4rem;
}

.profile-character-description {
	padding: 0rem 2rem 2rem 0;
	overflow: hidden;
	flex-basis: 70%;
}

.profile .term, .profile .definition {

}

.profile-character-description-inner, .profile-sidebar-inner, .items-container, .items-header {
	position: relative;
}

.term {
	margin-left: 1.4rem;
}