.point-at {
	cursor: pointer;
}

.looking-container {
	position: absolute;
    left: 0;
    top: 0;
    z-index: 11;
    background: var(--black);
    border: 0.4rem solid var(--white);
    padding: 0.4rem;
	max-width: 22.1ch;
	min-width: 22.1ch;
	min-height: 2.4rem;
	padding-bottom: 1.8rem;
}

.looking-title {
	margin: 0;
	padding: 0;
}

.looking-description {
	display: inline-block;
	padding: 0;
	margin: 0;
}

.order {
	text-transform: capitalize;
	color: var(--info);
}

.looking-container p {
	margin: 0 !important;
}

.graffiti {
	min-width: 26rem;
	min-height: 16rem;
}

.drawing {
	margin-top: 3.2rem;
	margin-bottom: 1rem;
}

.dying, .dead, .impossible {
	color: var(--sg-red);
}

.badly-hurt, .impossible {
	color: var(--sg-red);
}

.hurt, .dimming, .difficult, .locked {
	color: var(--sg-sunflow-orange);
}

.slightly-hurt, .normal {
	color: var(--sg-yellow);
}

.healthy, .easy, .trivial, .unlocked {
	color: var(--sg-green);
}

.combat-stats {
	
}

.bottom-text {

}