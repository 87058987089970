.guard-house {
	display: flex;
	flex-direction: column;
	min-height: 100%;
}

.guard-house .top {
	flex-grow: 1;
	padding: 1rem;
	display: flex;
	flex-direction: column;
}

.guard-house .explanation-text {
	margin: 1rem 1rem 3.2rem;
}

.guard-house .list-text {
	margin: 1rem 1rem 3.2rem;
}

.guard-house .bottom {
	border-top: 0.4rem solid var(--white);
	display: flex;
}

.guard-house .bottom p {
	margin: 1rem;
}

.guard-house .weapon-deposit {
	margin-bottom: 3.2rem;
}