.neighbouring-character {
	display: flex;
}

.character-name {
	flex-grow: 1;
}

.kill-character {
	cursor: pointer;
}

.hidden {
	display: none;
}