.prompt-inner {
	position: absolute;
	top: 0;
	padding: 1.2rem;
	z-index: 999999;
}

.prompt-inner p {
	margin: 0;
	padding: 0;
	display: inline;
    background: #00000099;
}