li.disabled {
	pointer-events: none;
	opacity: 0.5;
}

.plant-action {
	cursor: pointer;
}

.plant-name {
	margin-top: 1rem;
}
