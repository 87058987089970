.dialogue-content {
    position: relative;
    overflow-y: auto;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 60%;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 0;
    white-space: pre-line;
    background: var(--black);
}

@media screen and (max-width: 1200px) {

  .keyboard-shortcuts {
    display: none;
  }
}

.dialogue-content li:first-child {
    margin-top: 0;
}

.greeting {
	margin: 4rem 2rem 2rem 4rem;
}

.dialogue-tree-options {
	margin-top: 0;
	margin-left: 3rem;
	list-style-type: none;
	padding: 0;
	line-height: 5.6rem;
}

.dialogue-tree-option {
	color: var(--white);
}

.helper-text {
	color: var(--secondary-light);
}

.menu-input {
    background: var(--black);
}

.outgoing, .incoming {
    line-height: 3.8rem;
    max-width: calc(100% - 8rem);
}

.dialogue-text{
	margin: 0;
    padding: 0;
    line-height: 3.8rem;
    word-wrap: break-word;
	padding-left: 3rem;
}

.outgoing {
	text-align: left;
    color: var(--info);
}

.bottom-text {
	position: absolute;
	bottom: -1.4rem;
	right: 2rem;
	background: var(--black);
}

.history {
	flex-grow: 1;
	overflow-y: auto;
    background: var(--black);
}

.event-date-and-name {
	display: flex;
    align-items: center;
}
