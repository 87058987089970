.drawing-surface {
	display: flex;
	height: 100%;
}

.canvas-container {
	flex-grow: 1;
	display: flex;
	justify-content: center;
}

.canvas {
	--width: 3;
	--height: 3;
    height: 800px;
    width: 800px;
    display: grid;
    grid-template-columns: repeat(var(--width), 1fr);
    grid-template-rows: repeat(var(--rows), 1fr);
    gap: 0;
    padding: 3px;
    position: relative;
    justify-content: center;
    align-self: center;
    padding: 0;
}

.tile {
	display: inline;
	background-color: var(--sg-black);
    border: inherit;
}

.row {
	display: block;
}

.current.tile {
	border: 1px solid var(--error);
}

.saving .current.tile {
	border: none;
}

.drawing-surface .buttons {
	display: block;
	padding: 1rem 0;
}

.drawing-surface .buttons > div {
	margin: 1rem 0;
}

@media screen and (min-width: 1500px) {
	.mark-button {
		font-size:  3.2rem;
		line-height:  3.2rem;
	}

	.drawing-surface .buttons {
		border-left: 0.4rem solid var(--white);
	}
}