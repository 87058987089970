.menu-container {
	width: 100%;
	max-height: 100%;
}

.menu-container .quantity {
	margin-left: 0.4rem;
}

.option-description-container {
	margin: 0;
	display: inline;
	flex-grow: 1;
}

.menu-items {
	list-style-type: none;
	padding: 0 0 0 3rem;
	margin: 0;
	line-height: 3.2rem;
}

.menu-items li {
	display: flex;
}

.trade-actions .menu-items li {
	display: block;
}

.menu-items div {
	display: inline;
}

.menu-items .shortcut {
	margin: 0 1.2rem 0 0;
}

.indentation-0 {

}

.indentation-1 {
	margin-left: 2.6rem;
}

.indentation-2 {
	margin-left: calc(2 * 2.6rem);
}

.indentation-3 {
	margin-left: calc(3 * 2.6rem);
}

.indentation-4 {
	margin-left: calc(4 * 2.6rem);
}

.indentation-5 {
	margin-left: calc(5 * 2.6rem);
}

.indentation-6 {
	margin-left: calc(6 * 2.6rem);
}

.indentation-7 {
	margin-left: calc(7 * 2.6rem);
}

.indentation-8 {
	margin-left: calc(8 * 2.6rem);
}

.indentation-9 {
	margin-left: calc(9 * 2.6rem);
}

.indentation-10 {
	margin-left: calc(10 * 2.6rem);
}

.indentation-11 {
	margin-left: calc(11 * 2.6rem);
}

.menu-actions {
	position: absolute;
	bottom: -1.8rem;
	right: 0.4rem;
	background: var(--black);
	padding: 0.4rem;
}

.action-shortcut:not(:first-child) {
	padding-left: 1.4rem;
}

.trade, .selected-trade, .marked-trade, .marked-trade-qty {
	min-width: 8rem;
	text-align: right;
	color:  var(--secondary);
}

.marked-trade {
	background: var(--error);
	float: right;
}

.inventory-actions .marked-trade {
	display: inline;
	background: none;
	color: var(--primary-light);
	margin: 0 1rem;
}

.inventory-actions .trade, .inventory-actions .selected-trade, .inventory-actions .marked-trade-qty {
	position: relative;
	min-width: 8rem;
	background: none;
	text-align: right;
	color:  var(--secondary);
	min-width: 2.4rem;
}

.marked-trade-qty img, .selected-trade img, .trade img {
	position: relative;
    width: 2.6rem;
    top: 0.2rem;
}

.trade-offer {
	min-width: 8rem;
	background: var(--secondary);
	text-align: right;
	color:  var(--black);
}

.marked-trade-qty, .selected-trade, .trade {
	margin: 0 1.4rem;
}

.inventory-actions .marked-trade-qty {
	background: none;
}

.red {
	color: var(--sg-red);
}

.yellow {
	color: var(--secondary);
}

.green {
	color: var(--sg-pale-green);
}

.blue {
	color: var(--sg-blue);
}

.orange{
	color: var(--sg-orange);
}

.indigo{
	color: var(--sg-pale-blue);
}
.violet {
	color: var(--primary);
}
.brown{
	color: var(--sg-brown);
}
.black{
	color: var(--black-lighter);
}
.white{
	color: var(--sg-white);
}
.grey{
	color: var(--sg-grey);
}
.navy{
	color: var(--sg-blue);
}
.purple{
	color: var(--sg-purple);
}
.lime {
	color: var(--sg-pale-green);
}
.lemon {
	color: var(--sg-yellow);
}

.book-title div, .book-title span, .book-title p {
	margin: 0;
	display: inline;
}