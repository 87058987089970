.sheet {
	display: flex;
	max-height: 100%;
}

.character-sheet {
	max-height: 100%;
    overflow: hidden;
}

.sheet-sidebar {
	padding-left: 3.4rem;
}

.skills-container {
	display: flex;
	margin-bottom: 3.2rem;
	margin-top: 2.2rem;
	white-space: nowrap;
}

.status, .skill-points, .brainchip-title {
	margin-bottom: 3.2rem;
}

p.brainchip-title-container {
	display: none;
	margin-bottom: 3.2rem;
	margin-left: -3.2rem;
}

.brainchip-title {
	color: var(--success);
}

.value {
	color: var(--info);
}

.menu-container {
	flex-shrink: 1;
}

.skill-purchase-menu {
	display: flex;
	flex-direction: row;
	min-height: 100%;
}

.details {
	border-left: 0.4rem solid var(--white);
	flex-grow: 1;
	flex-basis: 50%;
}

.brainchip-container {
	overflow: hidden;
}

.brainchip-container p {
	margin: 0;
}

.brainchip-menus-container {
	display: flex;
    max-height: 100%;
}

.brainchip-container-container {
	flex-basis: 50%;
	flex-grow: 0;
	flex-shrink: 0;
}