.pristine {
	color: var(--info);
}

.excellent {
	color: var(--sg-green);
}

.good {
	color: var(--sg-yellow);
}

.used, .tattered, .poor {
	color: var(--sg-sunflow-orange);
}

.breaking {
	color: var(--sg-red);
}

.bottom-ting-inner {
	max-height: 100%;
	max-width: 100%;
    overflow: hidden;
    margin-bottom: 1.2rem;
}