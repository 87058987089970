.fight-title {
	position: absolute;
	top: 1rem;
	left: 50%;
    transform: translate(-50%, -150%);
    background: #FFF;
    color: #999;
}

.equation {
	margin: 0;
}

.hiding {
	visibility: hidden;
}

.fight-hint {
	background-color: #EF2917;
	display: inline;
}

.kill-button {
	background-color: #EF2917;
	cursor: pointer;
}

.cancel-button {
	margin-left: 3rem;
	background-color: #0075F2;
	cursor: pointer;
}

.computed {
	color: #D6F5FF;
}