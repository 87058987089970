.login-page {
	z-index: 1;
	display: grid;
	line-height: 4.2rem;
}

.top-bar {
	position: absolute;
	top: 0;
	right: 0;
	margin-bottom: 0;
    font-size: 2.4rem;
    text-align: right;
    margin-bottom: 0;
    padding: 0 auto;
    z-index: 0;
}

.top-bar-container {
	margin: 0 auto;
}

.top-bar-container a {
	color: #ddd7cB;
	text-decoration: none;
	padding: 2rem;
}

.login {
	position: relative;
	display: flex;
	flex-direction: column;
}

.login-page img {
	box-shadow: 0 4px 4px 0 rgb(0 0 0 / 50%);
/*	width: 350px;*/
	max-width: 50%;
}

.screenshot-small {
	width: 30rem;
	height: fit-content;
	margin: 2rem;
	margin-bottom: 12rem;
}

.screenshot-small:first-child {
	margin-left: 4rem;
}

.login .login-main-bar {
	height: initial;
	flex-basis: 300px;
	justify-content: flex-end;
}

.login-main-bar {
    margin: 0 auto;
    position: absolute;
    bottom: 2rem;
    right: 2rem;
}

.newsletter {
	position: relative;
	background: #0B191E;
	border: 0.8rem solid #ddd7cB;
	padding: 1rem;
	margin: 7rem 0;
	text-align: center;
	text-shadow: 0 3px 4px rgb(0 0 0 / 50%);
}

.newsletter-text {
	color: #A4BEF3;
	font-size: 3.4rem;
	margin-bottom: 0;
}

.newsletter-input {
	margin-top: 1rem;
	margin-bottom: 4rem;
	margin-right: 2rem;
	max-width: 40rem;
}

.saved {
	display: block;
	font-size: 3.4rem;
	color: #ddd7cB;
	position: absolute;
	padding: 0 1rem;
	top: 0;
	right: 0;
	background: #00c36b;
	text-shadow: none;
}

.login-page .hidden {
	display: none;
}

.text {
	line-height: 3.3rem;
	font-size: 3rem;
	font-family: 'Inconsolata', monospace;
	width: 100%;
	color: #ddd7cB;
	text-shadow: 0 3px 4px rgb(0 0 0 / 50%);
}

.email {
	margin-top: 3rem;
}

.email, .password {
	background-color: transparent;
	border: none;
	border-bottom: 1px solid #16313B;
	line-height: 3rem;
	font-size: 3rem;
	color: #ccc;
	font-family: 'Inconsolata', monospace;
	width: 100%;
	outline: none;
}

.buttons {
	display: flex;
	user-select: none;
}

.newsletter-button, .login-button, .sign-up-button, .disabled-button {
	color: var(--white);
	font-size: 3rem;
	cursor: pointer;
	margin: 1rem 0 0;
	padding:  0.5rem 3rem;
	cursor: pointer;
	text-shadow: var(--white-box-shadow);
}

.newsletter-button, .login-button {
	background-color: var(--primary);
}

.sign-up-button {
	background-color: var(--secondary);
}

.disabled-button {
	opacity: var(--opacity);
	background-color: var(--primary);
	cursor: pointer;
}

.intro-image {
	z-index: 0;
    object-fit: cover;
    overflow: hidden;
    max-width: 100%;
    min-width: 100%;
}

.information {
	z-index: 0;
	display: flex;
    flex-direction: column;
    max-width: 1200px;
    margin: 0 auto;
}

.overview {
	display: flex;
	align-items: center;
}

.overview p, .overview li {
	flex-grow: 1;
	font-size: 3rem;
	color: #ddd7cB;
	text-shadow: 0 3px 4px rgb(0 0 0 / 50%);
	padding: 2rem;
	margin-top: 0;
}

.overview .list-heading {
	padding-bottom: 0;
	margin-bottom: 0;
}

.list {
	list-style-type: disc;
	margin-left: 4rem;
	max-width: 80rem;
}

.bottom-section .list {
	margin: 2rem auto 4rem;
}

.bottom-section p {
	margin-bottom: 0;
	padding-bottom: 0;
}

.list li {
	font-size: 3.2rem;
	margin: 1rem;
	padding: 0;
	line-height: 2.8rem;
}

.wrapper {
	width: 100%;
}

.adventure {
	color: var(--secondary);
}

.escape {
	color: var(--error);
}

.focus {
	color: var(--success);
}

.limited {
	color: var(--success);
	font-weight: bold;
}

.partnership {
	color: var(--info);
}

.beta {
	display: block;
	color: var(--info);
}

.tools {
	color: var(--error);
}

.login-error, .sign-up-error {
	position: absolute;
	padding: 1rem;
	border: 0.4rem solid var(--white);
	display: flex;
	overflow: auto;
	z-index: 3;
	background: var(--black-light);
	left: 50%;
    transform: translate(-50%, -50%);
    top: 50%;
    color: #ddd7cB;
	font-size: 3rem;
}

.login-error.hidden, .sign-up-error.hidden {
	display: none;
}

.funded {
	display:  flex;
	flex-direction:  column;
	font-size:  2.2rem;
	color: #ddd7cB;
	align-items: flex-end;
}

.funded span {
	padding-right:  2rem;
}