.quantity-input {
	position: absolute;
	padding: 0.4rem 0;
	border: 0.4rem solid var(--white);
	display: flex;
	flex-direction: column;
	overflow: auto;
	z-index: 2;
	background: var(--black);
	left: 50%;
    transform: translate(-50%, -50%);
    top: 50%;
    padding-bottom: 1rem;
    min-width: 27rem;
}

.input {
	font-size: 3.2rem;
	font-family: 'Inconsolata', monospace;
	color: var(--white);
	margin: 0 1rem;
	border: none;
	outline: none;
	background: var(--black);
	max-width: 10rem;
}

.input::selection {
	background: var(--secondary);
	color: var(--black);
}

.quantity-input-content {
	display: flex;
}

.quantity-input-explanation {
	margin-left: 1rem;
}