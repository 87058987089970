.workshop {
	display: flex;
    flex-direction: column;
    max-height: 100%;
    min-height: 100%;
    height: 100%;
}

.workshop-sidebar {
	width: 100%;
	flex-grow: 1;
	flex-basis: 50%;
    overflow: hidden;
    display: flex;
}

.workshop-sidebar .items, .workshop-sidebar .item-details {
	flex-basis: 50%;
}

.workshop-sidebar .items {
	border-right: 0.4rem solid var(--white);
	padding: 0;
	overflow: hidden;
}

.workshop-sidebar .item-details {
	padding: 0 2rem;
}

.workshop-sidebar .item-details h3 {
	margin: 0;
}

.workshop-upgrade-section {
	border-top: 0.4rem solid var(--white);
	padding: 2rem;
    overflow: hidden;
}

.workshop-upgrade-section p {
	margin:  0;
}

.upgrade-cost {
	margin-left: 6.4rem;
	display: flex;
	flex-direction: row;
}

.upgrade-items {
	max-height: 16.3rem;
	overflow: hidden;
}

.upgrade-cost li, .upgrade-items li {
	display: inline-block;
	margin-right: 3rem;
}

.out-of-stock {
	color: var(--black-lighter);
}

.search-bar {
	background: var(--black);
	position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border: 0.4rem solid var(--white);
}

.workshop-description {
	color: var(--secondary-light);
}