.menu-input {
	border-top: 0.4rem solid var(--white);
}

.textarea {
	background: var(--black);
    border: none;
    outline: none;
    font-size: 1.4vw;
    color: var(--white);
    width: 100%;
    height:  100%;

    font-family: 'Inconsolata', monospace;
	font-weight: 600;
	font-size: 3.2rem;
	padding: 2rem 1rem 1rem;
	pointer-events: none;
}

.textarea::selection {
	background: var(--info);
	color: var(--white);
}

textarea::-webkit-input-placeholder {
  color: var(--secondary);
}

textarea:-moz-placeholder { /* Firefox 18- */
  color: var(--secondary);
}

textarea::-moz-placeholder {  /* Firefox 19+ */
  color: var(--secondary);
}

textarea:-ms-input-placeholder {
  color: var(--secondary);
}

textarea::placeholder {
  color: var(--secondary);
}
