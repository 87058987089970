.events {
	overflow: hidden;
	max-height: 100%;
	min-height: 100%;
}

.events-inner {
	position: relative;
	padding: 0 1rem;
	flex-grow: 1;
}

.events-inner p {
	margin: 1rem 0;
}

.event-date {
	width: 10rem;
	display: inline-block;
}

.events-component {
	display: flex;
	min-height: 100%;
}

.events-filters {
	display: flex;
	flex-direction: column;
	flex-basis: 40rem;
	padding: 1rem;
	border-left: 0.4rem solid var(--white);
}

.events-filters span {
	padding: 1rem 0 0;
}

.arrow-up, .arrow-down {
	background: var(--info);
	padding: 0 0.4rem;
	color: var(--black);
	font-size: 4.2rem;
	padding-top: 0.8rem;
}

.arrow-down {
	position: absolute;
	bottom: 0;
	transform: rotate(180deg);
}