.writing-surface {
	display: flex;
	flex-direction: row-reverse;
	height: 100%;
    margin-left: 1rem;
    font-size: clamp(1.2rem, 3.2rem, 3.2rem);
}

.surface {
	position: relative;
	flex-grow: 1;
    top: 50%;
    transform: translate(0, calc(-50% - 1rem));
    max-height: 90rem;
    font-size: clamp(1px, 3vh, 32px);
    width: 62.1ch;
    height: calc(22 * 2ch);
}

.surface > div {
    border: 0.4rem solid var(--white);
    overflow: hidden;
    width: inherit !important;
    height: inherit !important;
    margin: auto auto;
    padding: 1rem;
}

.surface p {
	margin: 0;
	font-size: inherit;
	line-height: initial;
	word-break: break-all !important;
}

.writing-surface .buttons {
	display: block;
	flex-basis: 74rem;
	padding-left: 1rem;
	display: grid;
    grid-template-columns: auto auto;
    margin-bottom: 1rem;
    align-content: start;
    font-family: 'Silkscreen';
}

.text-styles-header {
	margin: 3.1rem 0 0rem;
}

.color-key {
	display: inline;
	padding: 0 1rem;
	margin-right: 1rem;
}

.mark-button {
	display: block;
	background: var(--black);
	color: var(--white);
	font-size: 2.4rem;
    line-height: 2.2rem;
    user-select: none;
	border: none;
	font-family:  'Silkscreen', 'Inconsolata', monospace;
	font-weight: 600;
	position: relative;
	left: 3rem;
}
@media screen and (min-width: 1500px) {
	.mark-button {
		font-size:  3.2rem;
		line-height:  3.2rem;
	}

	.writing-surface .buttons {
		border-left: 0.4rem solid var(--white);
	}
}

.save-button {
	background: var(--white);
	color: var(--black);
	font-size: 3.2rem;
	cursor: pointer;
	text-shadow: none;
	float: right;
	padding: 0 1rem;
	margin: 1rem 1rem 0;
}

.close-button {
	background: var(--white);
	color: var(--black);
	cursor: pointer;
	padding: 0 1rem;
	position: absolute;
	top: 0;
	right: 0;
}