.level-up-container {
	position: absolute;
    top: 50%;
    left: 50%;
    z-index: 99999;
    background: var(--black);
    border: 0.4rem solid var(--white);
    transform: translate(-50%, -50%);
    height: 15rem;
}

.level-up-message {
	padding: 1rem;
}

.level-up-message-title, .level-up-message-line {
	margin: 0;
}

.level-up-message-title {
	margin-bottom: 3.2rem;
}