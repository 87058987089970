.container {
	position: relative;
	color: var(--white);
	font-size: 3.2rem;
	line-height: 3rem;
	user-select: none;
	margin: 0;
	overflow: hidden;
}

.resize-bar {
	width: 0.6rem;
	background: #FFF7EB;
	height: 100%;
	order: 2;
	margin: 0 1rem;
	cursor: col-resize;
	flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 0.6rem;
}

#main {
    position: relative;
	display: flex;
	flex-direction: row-reverse;
	justify-content: flex-end;
	margin: 0;
	height: 100%;
}

.main-bar {
	display: flex;
	flex-direction: column;
	order: 1;
	margin-top: 4rem;
	flex-grow: 1;
	z-index: 3;
}

.character-list-sidebar, .info-sidebar {
	overflow-y: auto;
	flex-grow: 0;
	flex-shrink: 1;
	width: 100%;
	padding-bottom: 1rem;
}

.info-sidebar p {
	margin: 1rem 0;
}

.sidebar-tabs {
	border-bottom: 0.4rem solid #f1f1f1;
	top: 1px;
	display: flex;
}

.sidebar-tab {
	background: #f1f1f1;
	cursor: pointer;
	padding: 0 1rem;
	color: var(--white);

	opacity: 0.7;
}

.sidebar-tab--selected {
	cursor: pointer;
	background: #f1f1f1;
	color: var(--white);
	padding: 0 1rem;
}

.info-sidebar--hidden, .character-list-sidebar--hidden {
	display: none;
}

.messages-container {
	padding: 1rem;
}

.full-messages-container {
	border: 0.4rem solid var(--white);
	border-top: none;
	width: 100%;
	position: relative;
	margin: 10rem 1rem;
	z-index: 99999999;
	background: var(--black);
}

.fuller-messages-container {
	z-index: 9999999999;
}

.messages-title {
	margin: 0;
  background: var(--primary);
  color: var(--white);
}

.dialogue-title {
	margin: 0;
  background: var(--primary);
  color: var(--white);
  position: absolute;
  left: -0.4rem;
  right: -0.4rem;
  top: -3rem;
  height: 3rem;
}

.inventory-weight {
	color: var(--white);
}

.mobile-options {
	position: absolute;
	bottom: 0;
	right: 0;
	display: none;
}


@media screen and (max-width: 1200px) {
  .mobile-options {
  	display: block;
  }
}

.show-inventory {
	background: #EF8A17;
	padding: 0 1rem;
	cursor: pointer;
}

.show-crafting {
	color: var(--white);
	padding: 0 1rem;
	cursor: pointer;
}

.dialogue-close-button {
	position: absolute;
	top: -3rem;
	right: 0;
	cursor: pointer;
  padding: 0 1rem;
}

#main canvas {
	order: 3;
}

#main.hidden canvas {
	display: none;
}

#actions.hidden, #help.hidden, #error.hidden {
	display: none;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #888;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #f1f1f1;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #f1f1f1;
}

/* Firefox */
* {
	scrollbar-width: 10px;
	scrollbar-color: var(--white) var(--black-lighter);
}

.writing-type-selection {
	position: absolute;
  top: 50%;
  left: 50%;
  z-index: 99999;
  background: var(--black);
  border: 0.4rem solid var(--white);
  transform: translate(-50%, -50%);
  width: 50rem;
  height: 15rem;
}

.new-message-container {
	position: absolute;
  top: 50%;
  left: 50%;
  z-index: 99999;
  background: var(--black);
  border: 0.4rem solid var(--white);
  transform: translate(-50%, -50%);
}

.character-death-screen {
	border: 0.4rem solid var(--white);
	margin: 20rem auto;
	padding: 2rem;
	text-align: left;
	vertical-align: center;
	display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 80rem;
}

.character-death-screen p {
	margin: 1rem 0;
}

.read-writing-surface {
	display: flex;
  height: 100%;
}

.read-writing-surface-container {
	width: 100%;
}

.read-writing-surface .surface {
	margin: 0 auto;
}

.connection-state {
	background: var(--sg-green);
	color: var(--white);
	position: absolute;
  top: 0;
  right: 0;
  z-index: 9999999999;
}

.near-death-screen {
	position: absolute;
	z-index: 9999;
	bottom: 0;
	top: 0;
	left: 0;
	right: 0;
	background: var(--black);
}

.talk-to-character-in-world {
	margin-top: 0.8rem;
}