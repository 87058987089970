.location {
	padding-top: 2rem;
	padding-bottom: 2rem;
	padding-left: 0.5rem;
}

.location-id {
	display: block;
}

.location-input {
	border-radius: 0;
	line-height: 3.3rem;
    font-size: 3.2rem;
    font-family: 'Inconsolata', monospace;
    width: 100%;
    outline: none;
    background-color: transparent;
    border: none;
}

.location-input::placeholder {
	color: #FBFADA;
}

.season, .biome {
	display: block;
	margin-right: 2rem;
	text-transform: capitalize;
}

.hidden-location-name {
	display: none
}

.help-hint {
	opacity: 0.8;
    line-height: 3.3rem;
    position: absolute;
    top: 0.1rem;
    right: 0;
    cursor: pointer;
}

.active-order {
	color: var(--info);
	text-transform: capitalize;
	display: block;
}