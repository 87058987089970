
@keyframes move {
	0% {
		opacity: 1;
		transform: translate(0, 0);
	}

	20% {
		opacity: 1;
	}

	100% {
		transform: translate(2rem, 2rem);
		opacity: 0;
	}
}

@keyframes fade {
	0% {
		opacity: 1;
	}

	100% {
		opacity: 0;
	}
}

.on-screen-dialogue {
	position: absolute;
	z-index: 9999999;
	text-shadow: 0.2rem 0.2rem 0.5rem var(--black);

	animation-name: move;
	animation-duration: 5s;
	max-width: 50rem;

	opacity: 0;
}

.on-screen-dialogue-speech-tag {
	content: ' ';
    width: 0.1rem;
    height: 0.8rem;
    background: var(--white);
    bottom: -1rem;
    right: 2rem;
    position: absolute;
    transform: rotate(-45deg);
	z-index: 9999999;

	animation-name: fade;
	animation-duration: 5s;

	opacity: 0;
}

.on-screen-dialogue-speech-tag.right {
    transform: rotate(45deg);
}