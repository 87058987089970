.farm-actions .hidden {
	display: none;
}

.action {
	cursor: pointer;
}

.working {
	opacity: 0.8;
	cursor: initial;
}

.unskilled-penalty {
	color: #ff495c;
}