.startup {
	position: absolute;
	color: #FFF7EB;
	font-size: 3.2rem;
	display: flex;
	width: 100%;
	max-width: 120rem;
	left: 50%;
	transform: translate(-50%, 0);
	flex-direction: column;
	justify-content: center;
	top: 20vh;
	height: calc(100% - 40vh);
}

.header {
	background:  var(--primary);
	margin: 0;
	padding: 0;
	text-align: center;
}

.options-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	border:  0.4rem solid var(--white);
	border-top: none;
	height: 100%;
	justify-content: center;
	overflow: hidden;
}

.options {
	margin: 0;
	padding: 0;
	list-style-type: none;
	color:  var(--white);
}

.footer {
    position: absolute;
    bottom: 0;
    margin: -2.4rem;
    background: var(--black);
    padding: 0 1rem;
    color:  var(--white);
}

.action {
	color: var(--secondary);
}

.disabled, .disabled .action {
	color: var(--black-lighter);
}

.menu-container {
	width: auto !important;
}