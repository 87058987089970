.direction {
	color: var(--black-lighter);
	cursor: pointer;
}

.selected-direction {
	color: var(--white);
}

.direction-input {
	position: absolute;
	padding: 0.4rem 0;
	border: 0.4rem solid #FFF7EB;
	display: flex;
	flex-direction: column;
	width: 35rem;
	overflow: auto;
	z-index: 2;
	background: var(--black);
	left: 50%;
    transform: translate(-50%, -50%);
    top: 50%;
}

.door-box {
	width: 90%;
	margin: 3rem auto 0;
	height: 20rem;
	position: relative;
}

.north {
	position: absolute;
	top: 0;
	left: 50%;
	transform: translate(-50%, 0%);
}

.east {
	position: absolute;
	right: 0;
	top: 50%;
	transform: translate(0, -75%);
}


.south {
	position: absolute;
	bottom: 0;
	left: 50%;
	transform: translate(-50%, 0%);
}

.west {
	position: absolute;
	left: 0;
	top: 50%;
	transform: translate(0, -75%);
}

.up {
	position: absolute;
	top: -3rem;
	left: 0;
}

.down {
	position: absolute;
	top: -3rem;
	right: 0;
}

.button-container {
	padding-top: 3rem;
}