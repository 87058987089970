.inventory-actions {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0rem;
	right: 0;
	padding: 1rem;
	margin: 1rem;
	border: 0.4rem solid var(--white);
	display: flex;
	flex-direction: column;
	z-index: 0;
	margin-top: 10rem;
    margin-bottom: 10rem;
    display: flex;
	position: relative;
	flex-grow: 1;
	max-width: calc(100% - 4.8rem);
}

.top-ting {
	display: flex;
	overflow: hidden;
	width: 100%;
}

.bottom-ting {
	border-top: 0.4rem solid var(--white);
	padding-top: 1rem;
	max-height: 80%;
}

.bottom-ting p {
	margin: 0;
}

.inventory-actions .hidden {
	display: none;
}

.inventory-title {
	position: absolute;
	top: -0.5rem;
	left: 25%;
    transform: translate(-50%, -150%);
    background: var(--black);
}

.character-traits .inventory-total-weight {
	top: initial;
	bottom: 4rem;
}

.tile-title {
	position: absolute;
	top: -0.5rem;
	left: 75%;
    transform: translate(-50%, -150%);
    background: var(--black);
}

.inventory-actions .crafting-close-button {
	top: -1.8rem;
}

.quantity:before {
	color: var(--white);
    content: '(';
}

.quantity:after {
	color: var(--white);
    content: ')';
}

.inventory-ctas {
	text-align: right;
}

.inventory-ctas span {
	cursor: pointer;
}

.weight {
    color: var(--sg-teal);
    margin-left: 1rem;
    display: none;
    text-align: right;
    font-weight:  700;
    height: 3.2rem;
}

.inventory-actions .weight {
    display: inline-block;
}

.inventory-total-weight, .tile-total-weight {
	position: absolute;
	bottom: 0;
	margin: 0;
	left: 50%;
    transform: translate(-100%, 150%);
    background: var(--white);
    color: var(--black);
}

.tile-total-weight {
	display: none;
}

.inventory-actions .tile-total-weight {
    display: block;
	left: 100%;
}

.tile-title {
	display: none;
}

.inventory-actions .tile-title {
	display: block;
}

.my-inventory .character-select {
	position: absolute;
}

.main-bar .my-inventory ul, .main-bar .my-inventory .inventory-title {
	display: none;
}

.main-bar .crafting-close-button {
	display: none;
}

.main-bar .my-inventory {
	min-height: 3.2rem;
	display: inline;
}

.detail {
	overflow: hidden;
	white-space: nowrap;
    text-overflow: ellipsis;
}

.main-bar .my-inventory .inventory-total-weight {
	position: relative;
	transform: initial;
	bottom: initial;
	left: initial;
	margin: 0;
	overflow: hidden;
	display: inline;
	color: var(--white);
	background: initial;
}

.tile-inventory {
	flex-grow: 1;
}

.drop--loading {
	cursor: initial;
	opacity: 0.8;
	pointer-events: none;
}

.showing-option {
	display: inline;
}

.hidden-option {
	display: none;
}

.indented {
	margin-left:3rem;
}

.inventory-actions-menu-container {
	overflow: hidden;
	height: 100%;
}