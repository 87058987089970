.conversation-tree-wizard {
	display: flex;
    flex-direction: column;
    height: 100%;
}

.scheduler-actions {
	display: flex;
	flex-direction: row;
}

.conversation-tree-actions-container {
	border-top: 0.4rem solid var(--white);
	padding: 2rem;
	flex-grow: 1;
}

.conversation-tree-actions-container p {
	margin: 0;
}

.conversation-tree-wizard-container {
	padding: 0 2rem 2rem 4rem;
	flex-grow: 1;
	overflow: auto;
	overflow-x: visible;
	pointer-events: none;
}

.conversation-wizard-edit-text-container {
	position: absolute;
	top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 99;
}

.conversation-wizard-edit-text {
	border: 0.4rem solid var(--white);
}

.conversation-wizard-exit {
	background: var(--black);
	text-align: right;
	background: var(--white);
	margin: 0;
	padding: 0 1rem;
	color: var(--primary);
	position: relative;
	top: 0.8rem;
}

.conversation-wizard-edit-actions {
	border: 0.4rem solid var(--white);
	background: var(--black);
	padding: 2rem;
}

.selected-line {
	color: var(--info);
}