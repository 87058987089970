.character-select {
	position: relative;
	padding: 1rem;
	background: var(--black);
	z-index: 2;
	left: 50%;
    transform: translate(-50%, -50%);
    top: 50%;
    border: 0.4rem solid var(--white);
}

.character-select .select-items-menu-container {
	padding: 1.4rem 0;
	min-width: 40rem;
}

.character-select-title {
	margin: 0;
	margin-bottom: 1.4rem;
}

.character-select ul {
	min-width: 60rem;
	margin-top: 3rem;
}

.character-select ul li {
	cursor: pointer;
}