.messages {
    position: relative;
    overflow-y: hidden;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 60%;
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: row;
}

@media screen and (max-width: 1200px) {

  .keyboard-shortcuts {
    display: none;
  }
}

.messages li:first-child {
    margin-top: 0;
}

.message-text .character-name {
    color: var(--white);
    cursor: pointer;
    display: block;    
}

.position-character-name {
    display: inline-block;
    width: initial;
}

.new-message {
    background-color: transparent;
    border: 2px solid transparent;
    border-bottom: 1px solid #16313B;
    line-height: 3.3rem;
    font-size: 3.2rem;
    color: #ccc;
    font-family: 'Inconsolata', monospace;
    width: 100%;
    outline: none;
    padding: 0 5px;
}

.new-message.highlighted {
    border:  2px solid var(--info);
}

.sidebar-messages-list {
    display: flex;
    flex-direction: column;
    overflow-y: hidden;
    width: 100%;
    align-items: flex-start;
    padding-top: 20px;
    overflow-wrap: anywhere;
}

.link {
    text-decoration: underline;
    cursor: pointer;
}

.speak {
    position: absolute;
    bottom: 0.2rem;
    right: 0;
    background-color: #327086;
}

.message-text {
    padding:  2px 15px;
    line-height: 3.2rem;
    white-space: pre-line;
    text-shadow: var(--white-box-shadow);
}

.message-date {
    position: absolute;
    bottom: 0;
    right: 0;
    text-shadow: var(--white-box-shadow);
}

.system-message-text {
    color: #888;
}

.position-message {
    cursor: pointer;
}

.position-message span {
    display: inline;
}

.hidden-message-part {
    display: none;
}

.load-more-button--hidden {
    display: none;
}

.characters-list {
    flex-basis:  400px;
    flex-shrink:  0.5;
    overflow-y: auto;
    overflow-x: hidden;
}

.characters-list .character {
    cursor:  pointer;
    display: flex;
    height:  50px;
    line-height: 50px;
    padding:  10px 0;
    align-items: center;
}

.characters-list .character.selected {
    background-color:  var(--primary-light);
}

.characters-list .character.highlighted {
    height: 46px;
    border: 2px solid var(--info);
}

.characters-list .character.highlighted .character-face {
    margin-left:  8px;
}

.reader-message, .non-reader-message, .new-message {
    position: relative;
    width:  400px;
    margin-top:  40px;
}

.sidebar-message, .new-message {
    position:  relative;
    width:  100%;
    margin-bottom: 4px;
}
