.dialogue-content {
    position: relative;
    overflow-y: auto;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 60%;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

@media screen and (max-width: 1200px) {

  .keyboard-shortcuts {
    display: none;
  }
}

.dialogue-content li:first-child {
    margin-top: 0;
}

.greeting {
	margin: 4rem 2rem 0;
}

.dialogue-tree-options {
	margin-top: 0;
	margin-left: 3rem;
	list-style-type: none;
	padding: 0;
	line-height: 5.6rem;
}

.dialogue-tree-option {
	color: var(--white);
}

.helper-text {
	color: var(--secondary-light);
}

.outgoing, .incoming {
    margin: 0 2rem;
}

.outgoing {
	text-align: right;
    color: var(--info);
}

.bottom-text {
	position: absolute;
	bottom: -1.4rem;
	right: 2rem;
	background: var(--black);
}