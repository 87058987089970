.actions {
	position: absolute;
	padding: 1rem;
	border: none;
	display: flex;
	overflow: auto;
	z-index: 3;
	background: #275668;
	border: 0.4rem solid #FFF7EB;
	left: 50%;
    transform: translate(-50%, -50%);
    top: 50%;
}

.actions-title {
	position: absolute;
	top: 0;
	left: 50%;
    transform: translate(-50%, -150%);
    background: #FFF;
    color: #999;
}

ul {
	list-style-type: none;
	margin: 0;
	padding: 0;
}

.actions .hidden {
	display: none;
}

.actions-sub-menu {
	margin-left: 2rem;
    margin-right: 2rem;
}