.messages {
    position: relative;
    overflow-y: hidden;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 60%;
    width: 100%;
    height: calc(100% - 3rem);

    display: flex;
    flex-direction: row;
}

@media screen and (max-width: 1200px) {

  .keyboard-shortcuts {
    display: none;
  }
}

.chat-message {
    width: 800px;
    margin-top: 20px;
}

.messages li:first-child {
    margin-top: 0;
}

.message-text .character-name {
    color: var(--white);
    cursor: pointer;
    display: block;    
}

.position-character-name {
    display: inline-block;
    width: initial;
}

.new-message {
    background-color: transparent;
    border: 2px solid transparent;
    border-bottom: 1px solid #16313B;
    line-height: 3.3rem;
    /*font-size: 3.2rem;*/
    color: #ccc;
    font-family: 'Inconsolata', monospace;
    width: 100%;
    outline: none;
    padding: 0 5px;
}

.new-message.highlighted {
    border:  2px solid var(--info);
}

.messages-list {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    height: calc(100% - 3rem);
    padding-top: 20px;
    padding-left: 3rem;
    width: 800px;
}

.link {
    text-decoration: underline;
    cursor: pointer;
}

.speak {
    position: absolute;
    bottom: 0.2rem;
    right: 0;
    background-color: #327086;
}

.messages-text-not-sidebar {
    width: 800px;
}

.message-text, .system-message-text {
    padding:  4px;
    line-height: 3.2rem;
    white-space: pre-line;
    text-shadow: var(--white-box-shadow);
}

.message-date {
    /*position: absolute;
    bottom: 0px;
    right: 0;*/
/*    text-shadow: var(--white-box-shadow);*/
}

.system-message-text {
    color: var(--black-lighter);
}

.position-message {
    cursor: pointer;
}

.position-message span {
    display: inline;
}

.hidden-message-part {
    display: none;
}

.load-more-button--hidden {
    display: none;
}

.characters-list {
    flex-basis:  400px;
    flex-shrink:  0.5;
    overflow: hidden;
}

.characters-list .character {
    cursor:  pointer;
    display: flex;
    height:  50px;
    line-height: 50px;
    padding:  10px 0;
    align-items: center;
}

.characters-list .character.selected {
}

.characters-list .character.highlighted {
}

.characters-list .character .character-face {
    margin-left:  10px;
}

.characters-list .character.highlighted .character-face {
    margin-left:  8px;
}

.reader-message {
    color: var(--info);
}

.reader-message, .non-reader-message, .new-message {
    position: relative;
}

.reader-message, .new-message {
    margin-bottom: 4px;
}

.non-reader-message.highlighted, .reader-message.highlighted {
    background: var(--info);

}
.non-reader-message.highlighted, .reader-message.highlighted {
    color: var(--black);
}

.reader-message.faded {
    opacity: var(--opacity);
}

.reader-message.faded:hover {
    opacity: 1;
}