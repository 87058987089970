.equipment {
	display: flex;
	max-height: 100%;
	padding-left: 3rem;
	overflow: auto;
	height: 100%;
}

.equipment-container {
	display: flex;
	flex-direction: row;
}

.equipment h3 {
	font-size: 3.2rem;
	font-weight: 600;
	margin: 3.2rem 0 0;
}

.equipment-sidebar {
	padding: 0 2rem;
	flex-basis: 650px;
}

.equipment-container .menu-container:first-child {
	width: auto;
	flex-shrink: 0;
}

.equipment-container .menu-container:last-child {
	white-space: nowrap;
	margin-left: 2rem;
}