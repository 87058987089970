.select-items {
	position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 99999999;
    background: var(--black);
    min-width: 55rem;
    min-height: 20rem;
    border: 0.4rem solid var(--white);
    padding-bottom: 3rem;
    max-height: 60%;
    display: flex;
    flex-direction: column;
}

.select-items-menu-container {
	overflow: hidden;
}

.select-items-actions {
	position: absolute;
    bottom: -1.5rem;
    background: var(--black);
}

.select-item-action {
	margin: 0;
}

.select-items-title {
    margin-left: 0.4rem;
    margin-top: 2rem;
    margin-bottom: 1rem;
}