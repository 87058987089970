.crafting-error {
	position: absolute;
	padding: 1rem;
	border: 0.4rem solid #FFF7EB;
	display: flex;
	overflow: auto;
	z-index: 2;
	background: #275668;
	left: 50%;
    transform: translate(-50%, -50%);
    top: 50%;
}