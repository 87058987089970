.help {
	position: absolute;
	top: 1rem;
	left: 1rem;
	bottom: 1rem;
	right: 1rem;
	padding: 1rem 0 1rem 1rem;
	margin: 0;
	border: 0.4rem solid var(--white);
	z-index: 0;
	margin-top: 10rem;
	margin-bottom: 10rem;
}

.help ul {
    column-count: 1;
}

.controls {
	display: block;
	color: var(--info);
	margin: 0;
}

.arrow-unicode {
	position: relative;
	left: -0.6rem;
}