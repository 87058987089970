.crafting-container {
	position: absolute;
	top: 1rem;
	left: 1rem;
	bottom: 1rem;
	right: 1rem;
	margin-top: 10rem;
	margin-bottom: 10rem;
}

.crafting {
	padding: 1rem 0 1rem 1rem;
	margin: 0;
	border: 0.4rem solid var(--white);
	border-top: none;
	display: flex;
	z-index: 0;
	height: 100%;
}

.crafting-close-button {
	position: absolute;
	top: -1.6rem;
	background-color: var(--black);
	right: 0.4rem;
	cursor: pointer;
    padding: 0 1rem;
}

.crafting-title {
	position: absolute;
	top: -0.5rem;
	left: 0;
	right: 0;
    transform: translate(0%, -5.6rem);
    background: var(--primary);
    text-align: center;
}

.search {
	background-color: transparent;
	border: none;
	border-bottom: 1px solid var(--white);
	line-height: 3rem;
	font-size: 3.2rem;
	color: #ccc;
	font-family: 'Inconsolata', monospace;
	width: 100%;
	outline: none;
}

ul {
	list-style-type: none;
	margin: 0;
	padding: 0;
}

.sub-menu {
    margin-left: 1rem;
    max-width: 30rem;
    min-width: 30rem;
    margin-right: 2rem;
    overflow-y: auto;
}

.confirmation {
	margin-left: 3rem;
	flex-grow: 1;
	padding-left: 1rem;
	overflow-y: auto;
}

.recipe {
	margin-top: 3rem;
	padding-bottom: 3rem;
	margin-bottom: 3rem;
}

.craft-button {
	color: var(--white);
	background-color: var(--black);
	cursor: pointer;
	display: inline-block;
}

.craft-button--disabled {
	color: var(--white);
	background-color: #999;
	cursor: initial;
	display: inline-block;
	pointer-events: none;
}

.selected {
	position: relative;
}
.selected::before {
	content: '>';
	background: var(--info);
    color: var(--black);
    padding: 0 0.4rem;
    position: absolute;
    left: -3rem;
}

.success {
	visibility: hidden;
	position: absolute;
	top: 0;
	right: 0;
	color: var(--white);
	background-color: var(--black);
}

.success.showing {
	visibility: initial;
}