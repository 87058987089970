.name {
	color: var(--white);
	cursor: pointer;
	text-shadow: var(--white-box-shadow);
}

.character-skills li {
	display: inline-block;
	padding: 0 1rem;
}

.character-traits {
	display: grid;
	grid-template-columns: repeat(2, auto);
	justify-content: start;
	/*margin-left: 4rem;*/
	grid-row-gap:  0.2rem;
	padding-left: 0.5rem;
}

.hu {
	color: var(--success);
}

.hp {
	color: var(--error);
}

.in {
	color: var(--primary);
}

.dd {
	text-align: right;
	display: inline-block;
}

.first-row {
	display: flex;
	justify-content: space-between;
	padding-left: 0.5rem;
}

.logout {
	cursor: pointer;
	background: #D6F5FF;
	color: #8E8DBE;
}

.skill-traits {
	color: #00F587;
}

.fishing {
	background: #98B6B1;
}

.dyeing {
	background: #D7BCC8;
	color: #8E8DBE;
}

.mining {
	background: #FAC8CD;
	color: #8E8DBE;
}

.logging {
	background: #629677;
}

.masonry {
	background: #256EFF;
}

.cooking {
	background: #46237A;
}

.carpentry {
	background: #3DDC97;
}

.hunting {
	background: #FF495C;
}

.gathering {
	background: #DEF6CA;
	color: #8E8DBE;
}

.building {
	background: #F497DA;
}

.leatherworking {
	background: #A24936;
}

.farming {
	background: #D36135;
}

.clothmaking {
	background: #FFF275;
	color: #8E8DBE;
}

.armoury {
	background: #6699CC;
}

.pottery {
	background: #A23E48;
}

.jewellery {
	background: #FF8C42;
}

.smelting {
	background: #730071;
}

.baking {
	background: #DC9596;
}

.tailoring {
	background: #A9A587;
}

.unskilled {
	background: #FFF;
    color: #999;
}

.character-face {
	display: none;
	width: 50px;
	height:  50px;
	border-radius:  50px;
	background: var(--white);
	margin: 1rem 2rem 1rem 0;
}

.first-row-character {
	display: flex;
	flex-direction: row;
	align-items: center;
}